<template>
  <v-container fluid class="bg234 pa-0">
      <v-row class="justify-space-around">
        <v-col
        cols="12" sm="8" md="4"
          class="order-1 order-md-0 text-center "
        >
          <v-avatar size="100">
            <img src="@/assets/login/call-center.png" />
          </v-avatar>
          <p
              style="font-family: kelly slab !important;"
              class="text-subtitle2 letras"
            >
              <br />Somos a Easycob,<br />
              uma empresa com mais de <br />
              26 anos de atuação na área <br />
              de recuperação de crédito.
              <br /><br />
              Trabalhamos com diversos parceiros,<br /> 
              incluindo empresas de telefonia,<br />
              água, internet, saneamento, entre outros.<br /><br />
              Estamos com ótimas ofertas para você <br />
              quitar seus débitos com descontos.
            </p>
        </v-col>
        <v-col
          class="text-center align-center justify-center order-0 order-md-1 "
          cols="12" sm="8" md="4"
        >
          <img
            src="@/assets/login/easycob.svg"
            alt="Logo da Easycob"
            width="230px"
            class="pt-2"
          />
          <v-card class="rounded-xl mx-6" >
            <div class="text-subtitle1 font-weight-light py-10" style="color: #2899D6;">
              Aproveite a oportunidade de negociar seu débito! <br />
              Informe seu <strong>CPF</strong> ou <strong>CNPJ</strong> abaixo
              para ver a oferta.
            </div>
            <v-card-actions>
              <v-row no-gutters>
                <v-text-field
                  placeholder="CPF/CNPJ"
                  outlined
                  v-model="login"
                ></v-text-field>
                <v-btn dark x-large color="primary" @click="enviar"> 
                  <v-icon large>mdi-arrow-right-bold</v-icon> 
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { api } from "@/conf/api";
import moment from "moment";
export default {
  name: "TimLogin",
  data() {
    return {
      cpf: "",
      cgc: "",
      contrato: "",
      login: "",
      mostra: false,
    };
  },
  mounted() {
    this.cgc = this.$route.params.cgc;
    this.cpf = this.$route.params.cpf;
    this.contrato = this.$route.params.contrato;
  },
  methods: {
    async enviar() {
      this.mostra = true;
      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");
      //console.log(cpf);
      if (cpf != this.cpf || !cpf) {
        this.$store.commit("showMessage", {
          text: "Sua senha está errada!",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      }
      try {
        await api.post("Logs/inserecpflog",{cpf: this.cpf})
        let res = await api.get(
          `tim/oferta/${this.cgc}/${this.cpf}/${this.contrato}`
        );
        let boleto = res.data.dados;
        // console.log(boleto);
        if (boleto.cli.cpdatven == null) {
          this.$store.commit("showMessage", {
            text: "Erro de venc! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        //boleto.cli.cpdatven.substr(0, 10)
        let qrcode = boleto.cli.cpnomearquivo;
        let cpdatven = moment(boleto.cli.cpdatven.substr(0, 10)).subtract(
          1,
          "d"
        );

        let datavenc = new Date(boleto.venc.substr(0, 10));
        //datavenc = moment().add(3, "hours");
        let datahoje = new Date();
        datahoje.setHours(0);
        datahoje.setMinutes(0);
        datahoje.setMilliseconds(0);
        datahoje.setSeconds(0);
        // alert(datavenc);
        console.log(
          boleto.venc,
          cpdatven,
          boleto.cli.cpdatven,
          datahoje.toString(),
          datavenc < datahoje,
          datavenc > cpdatven
        );
        // alert(datahoje, cpdatven.toString());
        if (datavenc < datahoje || datavenc > cpdatven) {
          this.$store.commit("showMessage", {
            text: "Erro de data! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        let nome = boleto.nome.denome_razsoc;
        let contrato = this.contrato;
        let gsm = boleto.cli.cpinf.substr(8, 15);
        let v = boleto.venc.substr(0, 10);
        let vencimento =
          v.substr(8, 2) + "/" + v.substr(5, 2) + "/" + v.substr(0, 4);
        let barras = boleto.cli.cpobs.trim();
        let d1 = barras.substr(11, 1);
        let d2 = barras.substr(23, 1);
        let d3 = barras.substr(35, 1);
        let d4 = barras.substr(47, 1);
        let c1 = barras.substr(0, 11);
        let c2 = barras.substr(12, 11);
        let c3 = barras.substr(24, 11);
        let c4 = barras.substr(36, 11);
        let cb = c1 + c2 + c3 + c4;
        let ld =
          c1 +
          "-" +
          d1 +
          " " +
          c2 +
          "-" +
          d2 +
          " " +
          c3 +
          "-" +
          d3 +
          " " +
          c4 +
          "-" +
          d4;
        let valor_pagar = barras.substr(4, 7) + barras.substr(12, 2);

        let valor_pagar_f = Number(valor_pagar);
        let valor_pagar_str = `${valor_pagar_f},` + barras.substr(14, 2);

        let faturas = boleto.faturas;
        let valor_divida = 0;
        let faturas_str = [];

        faturas.forEach((v) => {
          let fatura = v.pdnumdoc;
          let venc2 = v.pddtvencto;
          let venc3 =
            venc2.substr(8, 2) +
            "/" +
            venc2.substr(5, 2) +
            "/" +
            venc2.substr(0, 4);
          let valor_fatura = v.pdvalor;
          //console.log(fatura, venc3, valor_fatura);
          valor_divida += parseFloat(valor_fatura);
          let val = valor_fatura.padStart(2, 0).replace(".", ",");
          let frase = `${fatura} - ${venc3} - R$ ${val}`;
          //console.log(frase);
          faturas_str.push(frase);
        });
        let valor_divida_str = valor_divida
          .toString()
          .padStart(2, 0)
          .replace(".", ",");

        let boleto_obj = {
          nome,
          contrato,
          cpf,
          gsm,
          vencimento,
          ld,
          cb,
          valor_pagar_str,
          valor_divida_str,
          faturas_str,
          qrcode,
        };
        //console.log(boleto_obj);
        this.login = "";
        this.$store.dispatch("insereOfertaTim", boleto_obj);

        this.$router.push("/ofertatimboleto");
      } catch (e) {
        console.log(e.message);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      } finally {
        this.mostra = false;
      }
    },
  },
};
</script>

<style scoped>
.letras {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
}
.bg234 {
  background: url("../../assets/login/fundo.png");
  background-size: cover;
  height: calc(100vh + 450px);
  background-color: #2899d6;
}

.ctn-apresentação {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
  width: 300px !important;
}
.img-card2 {
  top: 5px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 50px 50px 50px 50px;
  background-color: rgb(255, 255, 255);
}
.ctn-img-card {
  height: 533px !important;
  padding: 20px;
}
.parte2 {
  color: #2899d6;
}
</style>
